
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, onMounted, watch, Ref } from 'vue';
import { useRoute } from "vue-router";
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampEmptyListFeedback,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampTableSortRowsByColumn,
  CampFooterPaginationControl,
} from '@/components';
import { useProductStore } from "@/store/ProductStore"
import StockInfo from '@/views/Stock/Components/StockInfo.vue'
import moment from 'moment';
import { useLoaderStore } from "@/store/LoaderStore";
import { useStoreStore } from '@/store/StoreStore';
import { useCompanyStore } from '@/store/CompanyStore';
import { IObjEmit, ISortComponentCore } from '@/components/Table/CampTableSortRowsByColumn.vue';
import { campGoToTopByScrollMode } from '@/composables/Helpers';
import { IFilterStockExpiredSearchForm } from '@/layout/header/partials/filters/StockExpire/FilterStockExpireListPage.vue';
import { useFilterBaseV2Store } from '@/store/FilterBaseV2Store';

interface IExpiredStock {
  id: number
  created_at: Date
  created_atExpired: Date
  movement_id: string | number
  product: string
  quantityExpired: number
  quantityInput: number
  reason: string
  store: string
}

interface IFilterStockExpiredRequestParams extends IFilterStockExpiredSearchForm {}

export default defineComponent({
  name: "stockExpireList",
  components: {
    CampHeader,
    CampEmptyListFeedback,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampTableSortRowsByColumn,
    StockInfo,
    CampFooterPaginationControl
  },
  setup() {
    /** Variables */
    const { showTimeAlert } = useAlert()
    const filterBaseV2Store = useFilterBaseV2Store()
    const isLoading = ref(false)
    const loaderStore = useLoaderStore()
    const expiredStockList = ref<IExpiredStock[] | null>(null)
      const sortComponentCore = ref<ISortComponentCore>({
      currentColumn: "",
      rows: {},
      chronology: []
    })
    const searchParamsV2 = ref<IFilterStockExpiredRequestParams>({
      movement_id: null,
      startDate: null,
      endDate: null,
    })
    const filterDom = ref('')

    /** Methods */
    async function getStockExpireList(params: IFilterStockExpiredRequestParams) {
      try {
        const response = await axios.get(`/api/getExpiredStockByCompanyOrStoreInSession`,{ params })
        expiredStockList.value = response.data.data
        if(Array.isArray(expiredStockList.value))
          sortComponentCore.value.rows = expiredStockList.value
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      } finally {
        isLoading.value = false
        loaderStore.close()
      }
    }

    async function handleGetStockExpireList() {
      isLoading.value = true

      const searchFormV2 = filterBaseV2Store.getSearchForm<IFilterStockExpiredSearchForm | null>()
      if(searchFormV2)
        searchParamsV2.value = searchFormV2

      await getStockExpireList(searchParamsV2.value)
    }

    function sortRowsByColumn(obj: IObjEmit) {
      expiredStockList.value = obj.sortedList as []
      sortComponentCore.value = obj.sortComponentCore
    }

    /** Life Cycles */
    onMounted(() => handleGetStockExpireList())

    watch(() => filterBaseV2Store.checkForUpdates(), () => filterBaseV2Store.checkForUpdates() && handleGetStockExpireList())

    return {
      isLoading,
      expiredStockList,
      filterDom,
      moment,
      sortRowsByColumn,
      sortComponentCore,
    }
  }
})
